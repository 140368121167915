/// Creates a <div> with an image depending on the annotation type

export function annotationFactory(coordinate, options) {

  var div = document.createElement("div");
  div.className = `annotation ${options.data.type}-annotation`;

  if (options.data.type === "start") {
    div.innerHTML = resumeSharingAnnotationImg;
  }
  else if (options.data.type === "end") {
    div.innerHTML = endAnnotationImg;
  }
  else if (options.data.type === "pause") {
    div.innerHTML = pauseAnnotationImg;
  }
  else if (options.data.type === "resume") {
    div.innerHTML = resumeAnnotationImg;
  }
  else if (options.data.type === "waterContact") {
    div.innerHTML = waterContactAnnotationImg;
  }
  else if (options.data.type === "pauseSharing") {
    div.innerHTML = pauseSharingAnnotationImg;
  }
  else if (options.data.type === "resumeSharing") {
    div.innerHTML = resumeSharingAnnotationImg;
  }
  else if (options.data.type === "currentLocation") {
    div.innerHTML = currentLocationAnnotationImg;
    div.className = `annotation ${options.data.type}-annotation pulse`;
  }
  else {
    div.innerHTML = currentLocationAnnotationImg;
  }

  return div;
}

const startAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1118_22)">
    <circle cx="16" cy="16" r="16" fill="white"/>
    <circle cx="16" cy="16" r="12" fill="#169C1C"/>
    <path d="M21.3925 14.6165V20.1368C21.3925 21.38 20.7283 22.0458 19.4705 22.0458H12.9224C11.6689 22.0458 11.0004 21.3817 11.0004 20.1368V14.6165C11.0004 13.3708 11.6689 12.7074 12.9224 12.7074H14.5185V13.9741H13.0297C12.5327 13.9741 12.2635 14.227 12.2635 14.7454V20.0079C12.2635 20.5306 12.5327 20.7784 13.0297 20.7784H19.3632C19.8526 20.7784 20.1293 20.5306 20.1293 20.0079V14.7454C20.1293 14.227 19.8526 13.9741 19.3632 13.9741H17.87V12.7074H19.4705C20.7283 12.7074 21.3925 13.3724 21.3925 14.6165Z" fill="white" fill-opacity="0.85"/>
    <path d="M16.1943 17.4637C16.5181 17.4637 16.7795 17.1982 16.7795 16.8892V11.2594L16.7296 10.4182L17.0553 10.8464L17.804 11.6422C17.9055 11.7501 18.0449 11.8101 18.1876 11.8101C18.4654 11.8101 18.7031 11.6064 18.7031 11.3195C18.7031 11.166 18.6438 11.0589 18.538 10.9575L16.6441 9.13326C16.4937 8.98496 16.3519 8.93216 16.1943 8.93216C16.0409 8.93216 15.8984 8.98496 15.7445 9.13326L13.8497 10.9575C13.7483 11.0589 13.689 11.166 13.689 11.3195C13.689 11.6064 13.9215 11.8101 14.2002 11.8101C14.3429 11.8101 14.4866 11.7501 14.5872 11.6422L15.3367 10.8464L15.6633 10.4173L15.6134 11.2594V16.8892C15.6134 17.1982 15.8748 17.4637 16.1943 17.4637Z" fill="white" fill-opacity="0.85"/>
  </g>
  <defs>
    <clipPath id="clip0_1118_22">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;

const endAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1033_34)">
    <circle cx="16" cy="16" r="16" fill="white"/>
    <circle cx="16" cy="16" r="12" fill="#FF0000"/>
  </g>
  <defs>
    <clipPath id="clip0_1033_34">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;
const pauseAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1033_53)">
    <circle cx="16" cy="16" r="16" fill="#BE0000"/>
    <path d="M11.3063 24H13.5457C14.4002 24 14.8521 23.5482 14.8521 22.6838V9.30632C14.8521 8.41252 14.4002 8 13.5457 8H11.3063C10.4518 8 10 8.45181 10 9.30632V22.6838C10 23.5482 10.4518 24 11.3063 24ZM18.4469 24H20.6765C21.5408 24 21.9828 23.5482 21.9828 22.6838V9.30632C21.9828 8.41252 21.5408 8 20.6765 8H18.4469C17.5826 8 17.1308 8.45181 17.1308 9.30632V22.6838C17.1308 23.5482 17.5826 24 18.4469 24Z" fill="white" fill-opacity="0.85"/>
  </g>
  <defs>
    <clipPath id="clip0_1033_53">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;
const resumeAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1033_50)">
    <circle cx="16" cy="16" r="16" fill="#169C1C"/>
    <path d="M13.0293 23.7542C12.0836 24.3139 11 23.87 11 22.9049V9.06594C11 8.1202 12.1624 7.71487 13.0293 8.21671L24.5943 14.9335C25.4216 15.416 25.4414 16.5742 24.5943 17.076L13.0293 23.7542Z" fill="white" fill-opacity="0.85"/>
  </g>
  <defs>
    <clipPath id="clip0_1033_50">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;
const waterContactAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1033_43)">
    <path d="M16 31.9844C24.753 31.9844 32 24.7252 32 15.9922C32 7.24353 24.7372 0 15.9843 0C7.24705 0 0 7.24353 0 15.9922C0 24.7252 7.26273 31.9844 16 31.9844Z" fill="#0A84FF"/>
    <path d="M16 23.9099C12.6902 23.9099 10.4314 21.7619 10.4314 18.5478C10.4314 16.9485 11.1216 15.5218 11.5921 14.44C12.4863 12.5429 13.9137 10.1597 15.1686 8.26263C15.4196 7.88635 15.6706 7.71388 16 7.71388C16.3294 7.71388 16.596 7.88635 16.8313 8.26263C18.0863 10.1597 19.5294 12.5429 20.4079 14.44C20.8783 15.5218 21.5685 16.9485 21.5685 18.5478C21.5685 21.7619 19.3098 23.9099 16 23.9099Z" fill="white" fill-opacity="0.85"/>
  </g>
  <defs>
    <clipPath id="clip0_1033_43">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;


const currentLocationAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1033_37)">
    <circle cx="16" cy="16" r="16" fill="white"/>
    <circle cx="16" cy="16" r="12" fill="#1400FF"/>
  </g>
  <defs>
    <clipPath id="clip0_1033_37">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;

const pauseSharingAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1070_34)">
    <circle cx="16" cy="16" r="16" fill="#BE0000"/>
    <path d="M12.2153 13.8469C11.995 13.9822 11.8795 14.2285 11.8795 14.5885V20.5089C11.8795 21.0969 12.1823 21.3757 12.7414 21.3757H19.7441L21 22.6316C20.7154 22.7449 20.3766 22.8016 19.9873 22.8016H12.6207C11.2105 22.8016 10.4585 22.0544 10.4585 20.6539V14.4436C10.4585 13.6779 10.6829 13.1078 11.1181 12.7497L12.2153 13.8469ZM22.1496 14.4436V19.9869L20.7285 18.5659V14.5885C20.7285 14.0054 20.4172 13.7208 19.8666 13.7208H18.1868V12.2959H19.9873C21.4024 12.2959 22.1496 13.044 22.1496 14.4436Z" fill="white" fill-opacity="0.85"/>
    <path d="M16.8076 8.27492L18.9383 10.3271C19.0573 10.4413 19.124 10.5617 19.124 10.7344C19.124 11.0572 18.8566 11.2864 18.5441 11.2864C18.3835 11.2864 18.2267 11.2189 18.1125 11.0975L17.2703 10.2022L16.9038 9.72044L16.9599 10.6669V14.7973L15.6481 13.4855V10.6669L15.7042 9.71951L15.3368 10.2022L14.4936 11.0975C14.3804 11.2189 14.2188 11.2864 14.0582 11.2864C13.7447 11.2864 13.4831 11.0572 13.4831 10.7344C13.4831 10.5617 13.5498 10.4413 13.664 10.3271L15.7956 8.27492C15.9687 8.10808 16.1291 8.04868 16.3016 8.04868C16.4789 8.04868 16.6384 8.10808 16.8076 8.27492Z" fill="white" fill-opacity="0.85"/>
    <path d="M10.7126 9.70478L22.6373 21.6295C22.8423 21.8345 22.8423 22.1668 22.6373 22.3718C22.4323 22.5768 22.1 22.5768 21.895 22.3718L9.97029 10.4471C9.7653 10.2421 9.7653 9.90977 9.97029 9.70478C10.1753 9.49979 10.5076 9.49979 10.7126 9.70478Z" fill="white" fill-opacity="0.85"/>
  </g>
  <defs>
    <clipPath id="clip0_1070_34">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;

const resumeSharingAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1070_40)">
    <circle cx="16" cy="16" r="16" fill="#169C1C"/>
    <path d="M14.3727 12.3324V13.3853H12.433C11.7826 13.3853 11.4208 13.7347 11.4208 14.4058V20.7922C11.4208 21.4702 11.7826 21.8113 12.433 21.8113H18.0307C18.1189 22.1866 18.2619 22.5405 18.45 22.8656H12.4163C11.0586 22.8656 10.3733 22.1873 10.3733 20.8434V14.3547C10.3733 13.0093 11.0586 12.3324 12.4163 12.3324H14.3727ZM21.9286 14.3547V16.6171C21.5654 16.6268 21.2145 16.6886 20.8812 16.7925V14.4058C20.8812 13.7347 20.5069 13.3853 19.869 13.3853H17.9224V12.3324H19.8856C21.2503 12.3324 21.9286 13.0121 21.9286 14.3547Z" fill="white" fill-opacity="0.85"/>
    <path d="M16.5248 8.34748L18.7235 10.4718C18.8258 10.5671 18.8839 10.668 18.8839 10.8131C18.8839 11.0742 18.6779 11.2663 18.4043 11.2663C18.28 11.2663 18.1473 11.2138 18.0519 11.1115L17.0647 10.0622L16.6284 9.59689L16.6712 10.5758V17.2572C16.6712 17.5336 16.4308 17.7671 16.1475 17.7671C15.8712 17.7671 15.6307 17.5336 15.6307 17.2572V10.5758L15.6735 9.59548L15.2359 10.0622L14.2472 11.1115C14.1533 11.2138 14.0137 11.2663 13.8893 11.2663C13.6143 11.2663 13.4167 11.0742 13.4167 10.8131C13.4167 10.668 13.4747 10.5671 13.5701 10.4718L15.7703 8.34748C15.9057 8.2176 16.0162 8.17338 16.1475 8.17338C16.2857 8.17338 16.3949 8.2176 16.5248 8.34748Z" fill="white" fill-opacity="0.85"/>
    <path d="M25.411 20.8042C25.411 22.6112 23.8976 24.1177 22.0989 24.1177C20.2863 24.1177 18.7937 22.625 18.7937 20.8042C18.7937 18.9931 20.2863 17.499 22.0989 17.499C23.9197 17.499 25.411 18.9916 25.411 20.8042ZM21.0326 19.4836V22.1179C21.0326 22.3917 21.3078 22.5065 21.5429 22.3613L23.7114 21.0836C23.9424 20.9522 23.9217 20.6535 23.6714 20.4999L21.5429 19.2401C21.3092 19.1032 21.0326 19.2111 21.0326 19.4836Z" fill="white" fill-opacity="0.85"/>
  </g>
  <defs>
    <clipPath id="clip0_1070_40">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;

