import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/database';
import { annotationFactory } from './annotations.js';
import { format } from 'date-fns';
import './scss/styles.scss';
// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

// Define the LocationType mapping
const LocationType = {
    waypoint: "WayPoint",
    start: "Started Sharing",
    end: "Workout End",
    pause: "Workout paused",
    resume: "Workout resumed",
    waterContact: "Water Contact",
    resumeSharing: "Resumed sharing",
    pauseSharing: "Stopped sharing",
    currentLocation: "Current Location"
};

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAIkytNYBui9N6j1v8o8q7OC1ykdZRPRi0",
    authDomain: "livelocationsharing-b8a93.firebaseapp.com",
    databaseURL: "https://livelocationsharing-b8a93-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "livelocationsharing-b8a93",
    storageBucket: "livelocationsharing-b8a93.appspot.com",
    messagingSenderId: "577577369813",
    appId: "1:577577369813:web:f2574af7d7e63d666a225c",
    measurementId: "G-2F6PJPGP2H"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
const pushTokenID = "pushToken"

// Get sessionId from URL parameters
const urlParams = new URLSearchParams(window.location.search);
const sessionId = urlParams.get('sessionId');

// The max duration in milliseconds without any location update, if reached isSharing will be set to false
const isSharingThresholdMilliseconds = 60000

let startMarker, endMarker, polyline;

let segments = [[]];
let polylines = []
let timer; // Variable to store the timer

let initialLoadComplete = false;
let sessionRef;
let locationsRef;

// State to track sharing status
let isSharing = false;
let hasLocations = false

/* Map */

// Initialize Apple Maps
// Get or create token here: https://developer.apple.com/account/resources/services/maps-tokens
mapkit.init({
    authorizationCallback: function (done) {
        done("eyJraWQiOiJRUTRSRDMyQ1FTIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJXNzJRUzdFUjU0IiwiaWF0IjoxNzIyMTkyNTA0LCJvcmlnaW4iOiJnb3N1cGxpdmUud2ViY2hvaWNlLmNoIn0.4bU1KRHor_dA8VQTx2B6jkjcsY2pcHaDNwOwZQg96GA5LCoGQlQF6AFDXzLYL4wymtiHtSFrYgRkKbQeNZms3g");
    }
});


const map = new mapkit.Map("map", {
    showsUserLocation: false,
    showsMapTypeControl: true,
    showsZoomControl: mapkit.FeatureVisibility.Visible,
    mapType: mapkit.Map.MapTypes.Satellite,
    center: new mapkit.Coordinate(0, 0),
    region: new mapkit.CoordinateRegion(new mapkit.Coordinate(0, 0), new mapkit.CoordinateSpan(0.1, 0.1)),
    padding: new mapkit.Padding(20, 20, 20, 20)
});

function initMap() {

    mapkit.addEventListener("error", function (event) {
        console.log("error: " + event.status);
        switch (event.status) {
            case "Too Many Requests":
                // Exceeded number of requests allowed.
                break;
        }
    });
}

function registerButtons() {
    var centerMapButton = document.getElementById("center-map");

    if (centerMapButton) {
        centerMapButton.addEventListener("click", function () {
            adjustMapRegion();
            console.log("Adjust region")
        });

        // Also add touch event listener for mobile
        centerMapButton.addEventListener("touchstart", function () {
            adjustMapRegion();
            console.log("Adjust region 2")

        });

        console.log("Event listeners added");
    } else {
        console.error("Center map button not found");
    }


    var reloadButton = document.getElementById("reload-button");
    if (reloadButton) {
        // Reload Map
        reloadButton.addEventListener("click", function () {
            window.location.reload();
        });
    }

    updateNotificationButton();

}

// Define the loadAll function
function loadAll(sessionId) {
    console.log('Loading session with ID:', sessionId);
    // Your custom logic to handle the session data
    registerButtons();
    initMap();

    load(sessionId);
}

// Ensure loadAll is accessible globally
window.loadAll = loadAll;
window.toggleNotification = toggleNotification

// Export loadAll for Webpack (if needed)
export { loadAll };

// registerGlobalListener(sessionId); // Register listener first

/*
    Register Listeners
*/

const check = () => {
    if (!('serviceWorker' in navigator)) {
        throw new Error('No Service Worker support!')
    }
    if (!('PushManager' in window)) {
        throw new Error('No Push API Support!')
    }

    console.log("everythiny ready for push")
}

check();

messaging.onMessage((payload) => {
    console.log('Message received. ', payload);

    // Customize the notification here
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon || '/firebase-logo.png',
        click_action: payload.notification.click_action || '/'
    };

    if (Notification.permission === 'granted') {
        const notification = new Notification(notificationTitle, notificationOptions);
        notification.onclick = (event) => {
            event.preventDefault();
            window.open(notificationOptions.click_action, '_blank');
        };
    }
});

function registerGlobalListener(sessionId) {
    // Check if the listener is already registered
    if (locationsRef) {
        locationsRef.off('child_added'); // Unregister existing listener if any
    }

    locationsRef = firebase.database().ref(`locations/${sessionId}`);
    sessionRef = firebase.database().ref(`sessions/${sessionId}`);


    // Attach the listener for updates
    locationsRef.on('child_added', (snapshot) => {
        const location = snapshot.val();
        if (initialLoadComplete) {
            processLocation(location);
            drawPolyline();
        }
    });

    // Detect if the entire session node is deleted
    sessionRef.on('value', (snapshot) => {
        if (!snapshot.exists()) {
            // The session has been deleted, so clear the polylines

            if (polylines.length > 0) {
                map.removeOverlays(polylines);
                polylines = []; // Reset the polylines array
            }

            segments = [[]];

            // Remove all annotations from the map
            map.removeAnnotations(map.annotations);

        }
    });

    // Listen for session details updates
    sessionRef.on('value', (snapshot) => {
        const sessionData = snapshot.val();
        if (sessionData) {
            let locationName = sessionData.locationName
            if (locationName) {
                document.getElementById('location-name').textContent = locationName;
            }

            let userName = sessionData.userName
            if (userName) {
                document.getElementById('user-name').textContent = userName;
            } else {
                document.getElementById('user-name').textContent = `Guest`;
            }

            let deviceName = sessionData.deviceName
            if (deviceName) {
                document.getElementById('device-name').textContent = `${deviceName} `;
            }
        }

    });
}

function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        // Check if there's an existing service worker
        navigator.serviceWorker.getRegistration().then(existingRegistration => {
            if (existingRegistration) {
                // Unregister the existing service worker
                existingRegistration.unregister().then(() => {
                    console.log('Previous Service Worker unregistered');
                    // Register the new service worker
                    navigator.serviceWorker.register('/firebase-messaging-sw.js')
                        .then(function (registration) {
                            console.log('Service Worker registered with scope:', registration.scope);
                        }).catch(function (err) {
                            console.log('Service Worker registration failed:', err);
                        });
                }).catch(function (err) {
                    console.log('Service Worker unregistration failed:', err);
                });
            } else {
                // No existing service worker, directly register the new one
                navigator.serviceWorker.register('/firebase-messaging-sw.js')
                    .then(function (registration) {
                        console.log('Service Worker registered with scope:', registration.scope);
                    }).catch(function (err) {
                        console.log('Service Worker registration failed:', err);
                    });
            }
        }).catch(function (err) {
            console.log('Service Worker registration status check failed:', err);
        });
    } else {
        console.log('Service Workers are not supported in this browser.');
    }
}


/* 
Notifications
*/

function toggleNotification() {

    if (isMobileSafari()) {
        showNotificationNotPossibleAlert()
    } else {
        if (getExistingToken()) {
            disableNotifications();
        } else {
            requestPermission();
        }
    }

    updateNotificationButton();
}

function updateNotificationButton() {
    const notificationButton = document.getElementById('notificationButton');

    if (notificationButton) {
        if (getExistingToken()) {
            notificationButton.innerHTML = '<span class="bi bi-bell-slash-fill"></span>&nbsp;Disable Alerts';
            notificationButton.classList.remove("btn-success")
            notificationButton.classList.add("btn-danger")
        } else {
            notificationButton.innerHTML = '<span class="bi bi-bell-fill"></span>&nbsp;Enable Alerts';
            notificationButton.classList.remove("btn-danger")
            notificationButton.classList.add("btn-succesfs")
        }
    }
}

// Function to get sessionId from the URL path
function getSessionIdFromUrl() {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    return pathParts[pathParts.length - 1]; // Assumes sessionId is the last part of the path
}

// Function to request permission with sessionId from URL
function requestPermissionFromUrl() {
    const sessionId = getSessionIdFromUrl();
    if (sessionId) {
        requestPermission(sessionId);
    } else {
        console.error('No sessionId found in the URL');
    }
}

// Function to check if a token already exists in local storage
function getExistingToken() {
    const existingToken = sessionStorage.getItem(pushTokenID);
    if (existingToken) {
        console.log('Existing token found in local storage:', existingToken);
        return existingToken;
    } else {
        console.log('No existing token found in local storage.');
        return null;
    }
}

function isPWA() {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
    const iOSIsInstalled = window.navigator.standalone === true;

    // Returns true if the app is running as a PWA
    return iOSIsInstalled;
}

// Check if app is running in mobile safari, but not PWA
// Return: true if is mobile safari and no desktop, PWA etc.
function isMobileSafari() {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /Safari/.test(navigator.userAgent) && !/CriOS|Chrome/.test(navigator.userAgent);
    const iOSIsInstalled = window.navigator.standalone === true;

    // Returns true if the user is using Mobile Safari on iOS
    return isIOS && isSafari && !iOSIsInstalled;
}

function disableNotifications() {

    let currentToken = getExistingToken();
    if (currentToken) {
        removeTokenFromDatabase(sessionId, currentToken)
            .then(() => {
                console.log('Token removed from database.');
                return messaging.deleteToken(currentToken);
            })
            .then(() => {
                console.log('Token deleted.');
                sessionStorage.removeItem(pushTokenID); // Remove token from sessionStorage
                updateNotificationButton();
            })
            .catch(err => {
                console.error('Unable to disable notifications.', err);
                sessionStorage.removeItem(pushTokenID); // Remove token from sessionStorage
                updateNotificationButton();
            });
    }
}

function showNotificationNotPossibleAlert() {
    alert("To enable alerts add the website to your Homescreen and try again");
}

// Function to request permission and generate a new token if none exists
function requestPermission() {

    const sessionId = getSessionIdFromUrl();
    if (sessionId == null) {
        console.error('No sessionId found in the URL');
        return
    }

    let token = getExistingToken()
    if (token) {
        // Token already exists, no need to request permission or generate a new one
        console.log('Using existing token:', existingToken);
        // Optionally, you can still save or update the token in your database
        saveTokenToDatabase(sessionId, existingToken);
    } else {
        // No existing token, request permission to generate a new one
        console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                // Get registration token
                messaging.getToken({ vapidKey: 'BMKWTP0r3kQdlSJMAA7vpd7NiVoFp0jfB2Ba2noXdUfl8Hl4xTurKPFGcFs_ZF2oNA2nJ0wfUtZswNutUyf8XZw' }).then((currentToken) => {
                    if (currentToken) {
                        console.log('Current token:', currentToken);
                        // Save the token in local storage
                        sessionStorage.setItem(pushTokenID, currentToken);
                        // Save the token in the database under the specific session
                        saveTokenToDatabase(sessionId, currentToken);
                        registerServiceWorker();
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }

                    updateNotificationButton();

                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
            } else {
                console.log('Unable to get permission to notify.');
            }
        });
    }
}

// Save the token in your Firebase Realtime Database under the specific session
function saveTokenToDatabase(sessionId, currentToken) {
    firebase.database().ref(`fcmTokens/${sessionId}/${currentToken}`).set(true);
}

// Remove the token from your Firebase Realtime Database under the specific session
function removeTokenFromDatabase(sessionId, currentToken) {
    return firebase.database().ref(`fcmTokens/${sessionId}/${currentToken}`).remove();
}

function load(sessionId) {

    if (sessionId) {
        console.log("load session");

        registerGlobalListener(sessionId);

        const locationsRef2 = firebase.database().ref(`locations/${sessionId}`);
        // Load the entire route initially
        locationsRef2
            .orderByChild('timestamp')
            .once('value')
            .then(snapshot => {
                snapshot.forEach(childSnapshot => {
                    const location = childSnapshot.val();
                    processLocation(location);
                });
                drawPolyline(); // Call drawPolyline after all initial locations are processed
                adjustMapRegion();
                initialLoadComplete = true; // Set the flag indicating initial load is complete
            })
            .catch(error => {
                console.error("Error fetching locations: ", error);
            })

    } else {
        alert('Session ID not provided');
    }
}


// Adjust the map to fit all markers function
function adjustMapRegion() {

    let pathCoordinates = segments.flat();
    if (pathCoordinates.length === 0) return;

    const northLatitude = Math.max(...pathCoordinates.map(coord => coord.latitude));
    const southLatitude = Math.min(...pathCoordinates.map(coord => coord.latitude));
    const eastLongitude = Math.max(...pathCoordinates.map(coord => coord.longitude));
    const westLongitude = Math.min(...pathCoordinates.map(coord => coord.longitude));

    const centerLatitude = (northLatitude + southLatitude) / 2;
    const centerLongitude = (eastLongitude + westLongitude) / 2;
    const latitudeDelta = Math.abs(northLatitude - southLatitude) * 1.1; // Add some padding
    const longitudeDelta = Math.abs(eastLongitude - westLongitude) * 1.1; // Add some padding

    const region = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(centerLatitude, centerLongitude),
        new mapkit.CoordinateSpan(latitudeDelta, longitudeDelta)
    );
    map.setRegionAnimated(region, true);
}


// Function to determine sharing status
function updateSharingStatus(locations) {
    // Loop through locations to find the latest sharing status

    // Clear the previous timer if it exists
    if (timer) {
        clearTimeout(timer);
    }

    // Start a new timer
    timer = setTimeout(() => {
        // Action to perform after 10 seconds

        updateSharingUI(false);
        clearTimeout(timer)

    }, isSharingThresholdMilliseconds);

    var lastItem = locations.at(-1)

    if (locations.length === 0) {
        hasLocations = false
    } else {
        hasLocations = true
    }

    const timestamp = lastItem.timestamp;
    // console.log('Timestamp from database:', timestamp);

    // Convert the timestamp string to a Date object
    const timestampDate = new Date(timestamp);
    if (isNaN(timestampDate.getTime())) {
        console.log("Invalid timestamp, unable to parse.");
        return; // Early exit if the timestamp is invalid
    }

    // console.log('Parsed timestamp date:', timestampDate);

    // Get the current time
    const currentTime = new Date();
    // console.log('Current time:', currentTime);

    // Calculate the difference in milliseconds
    const timeDifference = currentTime.getTime() - timestampDate.getTime();
    // console.log('Time difference (milliseconds):', timeDifference);

    if (lastItem.type === "pauseSharing" || lastItem.type === "pause") {
        updateSharingUI(false);

    } else {
        updateSharingUI(true);
    }
}

function updateSharingUI(isSharing) {
    const ledIndicator = document.getElementById('led-indicator');
    const ledStatusText = document.getElementById('led-status-text');

    if (isSharing) {
        ledIndicator.classList.remove('led-red', 'led-yellow');
        ledIndicator.classList.add('led-green');
        ledStatusText.textContent = 'Sharing';
    } else {
        ledIndicator.classList.remove('led-green', 'led-yellow');
        ledIndicator.classList.add('led-red');
        ledStatusText.textContent = 'Not sharing';
    }
}

// Process and display location data
function processLocation(location) {

    let coordinate = new mapkit.Coordinate(location.latitude, location.longitude);

    segments[segments.length - 1].push(location)

    // Update sharing status whenever a new location is processed
    updateSharingStatus(segments.flat());

    // currentSegment
    if (location.type === "pause" || location.type === "pauseSharing") {
        segments.push([])
    }

    let allPathCoordinates = segments.flat();

    // const containsEndLocationWithoutEnd = allPathCoordinates.some(location => location.type === "end");

    /* Create Annotations */

    // Create start annotation
    if (allPathCoordinates.length === 1) {
        location.type = "start"
    }
    else {
        // if latest location is a waypoint, set as current location
        if (location.type === "waypoint") {
            location.type = "currentLocation"
        }
    }

    var calloutDelegate = {
        // Return a div element and populate it with information from the
        // annotation, including a link to a review site.
        calloutContentForAnnotation: function (annotation) {
            var element = document.createElement("div");
            element.className = "review-callout-content";
            var title = element.appendChild(document.createElement("h1"));
            title.textContent = annotation.data.name;

            var timeElement = element.appendChild(document.createElement("div"));
            timeElement.className = "time";
            timeElement.textContent = annotation.data.formattedTime;

            var dateElement = element.appendChild(document.createElement("div"));
            dateElement.className = "date";
            dateElement.textContent = annotation.data.formattedDate;

            // var parentContainer = element.appendChild(document.createElement("div"));
            //     // parentContainer.className = "parent-container";
            let batterylevel = annotation.data.batterylevel

            if (batterylevel) {
                var batterylevelContainer = element.appendChild(document.createElement("div"));
                batterylevelContainer.className = "hstack";

                var batterLevelElement = batterylevelContainer.appendChild(document.createElement("div"));
                batterLevelElement.className = "date"
                batterLevelElement.innerHTML = `<span class="bi bi-battery-full"></span>&nbsp;${Math.round(batterylevel)} %`;

                var spacer = batterylevelContainer.appendChild(document.createElement("div"));
                spacer.className = "spacer";
            }

            let waterTemperature = annotation.data.watertemperature
            if (waterTemperature) {

                var waterTemperatureContainer = element.appendChild(document.createElement("div"));
                waterTemperatureContainer.className = "hstack";

                // var icon = waterTemperatureContainer.appendChild(document.createElement("div"));
                // icon.innerHTML = waterTemperatureIcon;

                var waterTemperatureElement = waterTemperatureContainer.appendChild(document.createElement("div"));
                waterTemperatureElement.className = "date";
                waterTemperatureElement.innerHTML = `<span class="bi bi-droplet-fill"></span><span class="bi bi-thermometer-half"></span>&nbsp;${Math.round(waterTemperature)} °C`;

                var spacer = waterTemperatureContainer.appendChild(document.createElement("div"));
                spacer.className = "spacer";
            }



            // Add more content.
            return element;
        }
    };

    const date = new Date(location.timestamp); // Convert Firestore timestamp to JS Date
    const formattedDate = format(date, 'yyyy-MM-dd');
    const formattedTime = format(date, 'HH:mm:ss');

    let customAnnotation = new mapkit.Annotation(coordinate, annotationFactory,
        // options see: https://developer.apple.com/documentation/mapkitjs/annotationconstructoroptions
        {
            title: location.type.charAt(0).toUpperCase() + location.type.slice(1),
            callout: calloutDelegate,
            data: {
                formattedDate: formattedDate,
                formattedTime: formattedTime,
                name: getLocationName(location),
                type: location.type,
                batterylevel: location.batterylevel,
                watertemperature: location.watertemperature
            }
        }
    );

    // var customAnnotation = new mapkit.MarkerAnnotation(coordinate, {
    //     title: "San Francisco",
    //     subtitle: "California",
    //     callout: {
    //         title: "San Francisco",
    //         subtitle: "A beautiful city in California",
    //     }
    // });

    // don't show currentLocation location if one of these is processed
    if (location.type === "pause" || location.type === "pauseSharing" || location.type === "end") {
        if (endMarker) {
            map.annotation
            map.removeAnnotation(endMarker); // Remove the previous end marker
        }
    }

    // Remove existing current location annotation and keep new current location annotation
    if (location.type === "currentLocation") {
        if (endMarker) {
            map.removeAnnotation(endMarker); // Remove the previous end marker
        }
        endMarker = customAnnotation
    }
    map.addAnnotation(customAnnotation);
}

function drawPolyline() {
    // Remove all existing polylines from the map
    if (polylines.length > 0) {
        map.removeOverlays(polylines);
        polylines = []; // Reset the polylines array
    }

    var style = new mapkit.Style({
        lineWidth: 4,
        strokeColor: "#FF0000"
    });

    // Iterate over segments and add new polylines to the map
    segments.forEach(segment => {
        if (segment.length > 1) {
            let pathCoordinates = segment.map(location => new mapkit.Coordinate(location.latitude, location.longitude));
            let polyline = new mapkit.PolylineOverlay(pathCoordinates, { style: style });
            map.addOverlay(polyline);
            polylines.push(polyline); // Add the new polyline to the array
        }
    });
}

// Helper function to get the name from a location object
function getLocationName(location) {
    return LocationType[location.type] || "Unknown";
}

// Attach the function to the global window object
window.requestPermission = requestPermission;

// Call adjustMapRegion after the website has loaded
// window.onload = adjustMapRegion;

const batteryIconImg = `
<svg class="battery-icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
  <g clip-path="url(#clip0_1242_2)">
    <path d="M9.58815 34.9024H34.9174C37.6727 34.9024 39.9929 34.6671 41.6364 33.1667C43.28 31.6663 43.5217 29.5775 43.5217 27.0621V22.8404C43.5217 20.325 43.28 18.2362 41.6364 16.7358C39.9929 15.2354 37.6727 15 34.9174 15H9.5559C6.84894 15 4.5287 15.2354 2.8852 16.7358C1.24169 18.2362 1 20.3397 1 22.7962V27.0621C1 29.5775 1.24169 31.6663 2.8852 33.1667C4.5287 34.6671 6.84894 34.9024 9.58815 34.9024ZM9.15307 32.5341C7.49345 32.5341 5.75327 32.3282 4.77039 31.4456C3.80363 30.5483 3.59416 28.9744 3.59416 27.4593V22.4726C3.59416 20.9281 3.80363 19.3541 4.77039 18.4568C5.75327 17.5595 7.50956 17.3683 9.20141 17.3683H35.3686C37.0282 17.3683 38.7683 17.5742 39.7351 18.4568C40.718 19.3541 40.9275 20.9134 40.9275 22.4285V27.4593C40.9275 28.9744 40.718 30.5483 39.7351 31.4456C38.7683 32.3282 37.0282 32.5341 35.3686 32.5341H9.15307ZM45.713 28.7537C46.9859 28.6802 48.6939 27.1945 48.6939 24.9438C48.6939 22.708 46.9859 21.2223 45.713 21.1487V28.7537Z" />
    <path d="M8.29911 30.8278H27.8278C28.9396 30.8278 29.5841 30.6807 30.0352 30.2688C30.4864 29.857 30.6475 29.2685 30.6475 28.2536V21.6489C30.6475 20.6339 30.4864 20.0455 30.0352 19.6336C29.5841 19.2217 28.9235 19.0746 27.8278 19.0746H8.34744C7.18732 19.0746 6.5267 19.2217 6.09166 19.6336C5.6405 20.0455 5.47937 20.6486 5.47937 21.6783V28.2536C5.47937 29.2685 5.6405 29.857 6.09166 30.2688C6.54281 30.6807 7.20344 30.8278 8.29911 30.8278Z" />
  </g>
  <defs>
    <clipPath id="clip0_1242_2">
      <rect width="48" height="48" />
    </clipPath>
  </defs>
</svg>
`;

const waterTemperatureIcon = `
<svg class="waterTemperature-icon xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
  <path d="M37.8097 40.3104C39.2921 41.3707 40.3573 42.0669 42.1138 42.0669H42.2353C43.1595 42.0669 43.9078 42.8112 43.9078 43.7247C43.9078 44.644 43.1595 45.3758 42.2353 45.3758H42.1138C36.1155 45.3758 35.6596 41.6063 32.8136 41.6063C30.0113 41.6063 29.2646 45.3758 23.9568 45.3758C18.6365 45.3758 17.9023 41.6063 15.0941 41.6063C12.254 41.6063 11.7797 45.3758 5.79394 45.3758H5.67245C4.73567 45.3758 4 44.644 4 43.7247C4 42.8112 4.73567 42.0669 5.67245 42.0669H5.79394C7.53797 42.0669 8.61568 41.3707 10.1039 40.3104C11.314 39.431 12.9129 38.2973 15.0941 38.2973C17.3315 38.2973 18.9429 39.481 20.153 40.373C21.4486 41.3508 22.3972 42.0669 23.9568 42.0669C25.498 42.0669 26.465 41.3508 27.7547 40.373C28.9707 39.481 30.5762 38.2973 32.8136 38.2973C35.0008 38.2973 36.5938 39.431 37.8097 40.3104ZM14.9258 30.328C14.9258 31.3767 15.1041 32.3829 15.4412 33.3137C15.3321 33.2914 15.2151 33.2845 15.0941 33.2845C12.254 33.2845 11.7797 37.054 5.79394 37.054H5.67245C4.73567 37.054 4 36.3221 4 35.4029C4 34.4894 4.73567 33.745 5.67245 33.745H5.79394C7.53797 33.745 8.61568 33.0489 10.1039 31.9886C11.2847 31.1304 12.8356 30.0302 14.9382 29.9805L14.9258 30.328ZM37.8097 31.9886C39.2921 33.0489 40.3573 33.745 42.1138 33.745H42.2353C43.1595 33.745 43.9078 34.4894 43.9078 35.4029C43.9078 36.3221 43.1595 37.054 42.2353 37.054H42.1138C36.1155 37.054 35.6596 33.2845 32.8136 33.2845C32.7034 33.2845 32.5962 33.2903 32.4969 33.3111C32.8344 32.3811 33.0129 31.3758 33.0129 30.328C33.0129 30.2107 33.0106 30.0944 33.0004 29.9814C35.0927 30.0409 36.629 31.1346 37.8097 31.9886Z" />
  <path d="M23.9568 36.7365C27.525 36.7365 30.3876 33.8891 30.3876 30.328C30.3876 27.8464 28.9303 25.9291 27.1537 24.8455C26.5545 24.4962 26.2654 23.976 26.2654 23.139V4.88303C26.2654 3.63189 25.2213 2.59329 23.9694 2.59329C22.705 2.59329 21.6676 3.63189 21.6676 4.88303V23.139C21.6676 23.951 21.3592 24.4586 20.7474 24.8204C18.9834 25.9041 17.5329 27.8464 17.5329 30.328C17.5329 33.8891 20.3954 36.7365 23.9568 36.7365ZM31.0631 7.09781H35.1458C35.8556 7.09781 36.3274 6.57348 36.3274 5.94664C36.3274 5.31398 35.8556 4.77715 35.1458 4.77715H31.0631C30.3534 4.77715 29.8816 5.31398 29.8816 5.94664C29.8816 6.57348 30.3534 7.09781 31.0631 7.09781ZM31.0631 12.152H35.1458C35.8556 12.152 36.3274 11.6152 36.3274 10.9825C36.3274 10.3557 35.8556 9.83136 35.1458 9.83136H31.0631C30.3534 9.83136 29.8816 10.3557 29.8816 10.9825C29.8816 11.6152 30.3534 12.152 31.0631 12.152ZM31.0631 17.2229H35.1458C35.8556 17.2229 36.3274 16.6986 36.3274 16.0659C36.3274 15.4391 35.8556 14.9148 35.1458 14.9148H31.0631C30.3534 14.9148 29.8816 15.4391 29.8816 16.0659C29.8816 16.6986 30.3534 17.2229 31.0631 17.2229ZM31.0631 22.3063H35.1458C35.8556 22.3063 36.3274 21.782 36.3274 21.1493C36.3274 20.5225 35.8556 19.9981 35.1458 19.9981H31.0631C30.3534 19.9981 29.8816 20.5225 29.8816 21.1493C29.8816 21.782 30.3534 22.3063 31.0631 22.3063Z" />
</svg>
`;

// const waterTemperatureIcon = `
// data:image/svg+xml;base64,PHN2ZyBjbGFzcz0id2F0ZXJUZW1wZXJhdHVyZS1pY29uIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJN ...
// `;


const endAnnotationImg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g clip-path="url(#clip0_1033_34)">
    <circle cx="16" cy="16" r="16" fill="white"/>
    <circle cx="16" cy="16" r="12" fill="#FF0000"/>
  </g>
  <defs>
    <clipPath id="clip0_1033_34">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
`;